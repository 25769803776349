.bell-icon{
    position: relative;
    margin-right: 10px;
    color: #73818f;
    
}

.bell-icon:hover{
    cursor: pointer;
    color: #2f353a
}

.red-circle{
    position: fixed;
    margin-top: -12px;
    margin-left: 4px;

    border-radius: 48%;
    border: 2px solid;
    border-color: white;

    width: max-content;
    min-width: 22px;
    height: min-content;
    padding: 2px;

    background-color: red;

    font-size: 10px;
    text-align: center;
    color: white;
}

.notification-container{
    font-size: 18px;

    position: absolute;
    top: 54px;
    right: 48px;

    height: 600px;
    width: 435px;
    
    background-color: white;
    border: 1px solid #e4e5e6;
    border-radius: 5px;
    box-shadow: 0px 9px 13px 5px #e4e5e6;
    
    overflow: scroll;
    overflow-x: hidden;
}

.single-container{
    margin: 5px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: left;
}

.article-container{
    margin: 4px;
    width: inherit;
}

.text{
    font-size: 16px;
}

.button{
    background: none;
    border: none;
}

.date-container{
    display: flex;
    justify-content: space-between;
}

.date{
    padding-right: 248px;
    font-size: 11px;
    color: #73818f;
}

.mark-container{
    font-size: 11px;
    color: #73818f;
}

.image-empty{
    position: absolute;
    top: 180px;
    left: 84px;


    height: 200px;
    width: 260px;
}

.text-empty{
    position: absolute;
    top: 400px;
    left: 106px;

    font-size: 13px;
}

.calendar-dividers{
    font-size: 11px;
    margin: 5px;
    padding-left: 17px;
    padding-top: 10px;
    color: #73818f;
}

