.inbox-circle {
    margin-top: -4px;
}

.circle-div {
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: #fff;
    font-size: 11px;
}

.wider{
    width: 27px
}

.convo {
    width: 28px;
    height: 28px;
    font-size: 14px;
}