
.ann {
    background-color: #FFFFD4;
    height: 30px;
    text-align: center;
    vertical-align: middle;
}

.ann p {
    font-size: 14px;
    line-height: 30px;
}


