.login-img {
  width: 250px;
  margin-bottom: 2em;
}

.login-btn {
  background-color: var(--lw-blue-color);
  color: #fff;
  padding-right: 60px;
  padding-left: 60px;
}

.customModal {
  width: 450px !important;
}

.modal-content-transition {
  /* Enable smooth height transitions */
  transition: height 0.3s ease;
}

.customModal {
  max-height: 90vh; /* Prevents modal from exceeding viewport height */
  overflow-y: auto; /* Enables scroll if content exceeds max-height */
}

.password-requirements {
  padding-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.password-requirements li {
  margin-bottom: 5px;
  font-weight: bold;
}
