.help-link {
  color: #73818f;
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 3px;
}

.help-link:hover {
  color: #2f353a;
  text-decoration: none;
}

.push-alert {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.push-alert p {
  margin-bottom: 5px;
}

.push-alert .note {
  font-size: small;
}

.push-alert div button {
  margin: 0, 10px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: 10%;
}

.MuiCircularProgress-colorPrimary {
  color: var(--lw-blue-color);
}

.MuiBadge-colorPrimary {
  color: #fff;
  background-color: var(--lw-blue-color);
}

.custom-dropdown-toggle {
  border: none !important;
  margin-bottom: 0px !important;
}

.custom-dropdown-toggle:hover {
  background-color: #eaeaea !important;
}

.custom-dropdown-toggle:focus {
  box-shadow: none !important;
  outline: none !important;
}

.logo-mobile img {
  margin-left: 24px; /* Move the logo 24px to the right */
}

@media only screen and (min-width: 750px) {
  .logo-mobile {
    left: 50% !important;
  }
}

@media only screen and (max-width: 750px) {
  .logo-mobile {
    left: 30% !important;
  }
}
