.wrapper {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh; /* Full viewport height */
}

.container {
  display: flex;
  flex-direction: row;
  width: 80%;
  max-width: 1200px;
  min-width: 350px;
  padding: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: white;
  margin: 0 auto; /* Centers the container horizontally */
  height: auto; /* Adjust height based on content */
}

.form-title {
  font-weight: bold;
  color: #4f5357;
}

.skipButtonContainer {
  position: absolute;
  top: 20px;
  right: 20px;
}

.skip-button {
  background-color: #f3f3f3;
  color: #5c5c5c;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}

.skip-button:hover {
  background-color: #e9e9e9;
  color: #161616;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.left-section {
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
}

.right-section {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}

.logo {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.steps {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.steps div {
  width: 30px;
  height: 30px;
  background-color: var(--lw-blue-color);
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.steps div:not(.active) {
  background-color: #d3d3d3;
}

.welcome {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.welcome-message {
  font-size: 16px;
  color: #6c757d;
  margin-bottom: 20px;
}

.form-group {
  background-color: #e9ecef;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.form-title {
  margin-bottom: 10px;
}

.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  margin-bottom: 10px;
}

.form-group p {
  font-size: 14px;
  color: #6c757d;
}

.buttons {
  margin-top: 10px;
  display: flex;
  justify-content: space-between; /* Align buttons to opposite ends */
}

.prev-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
  border: 2px solid var(--lw-blue-color);
  color: var(--lw-blue-color);
  padding: 10px 20px;
  border-radius: 5px;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.prev-button:hover {
  background-color: var(--lw-blue-color);
  color: white;
}

.next-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
  background-color: var(--lw-blue-color);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.next-button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.overview-text {
  margin-top: 10px;
  color: #6c757d;
  font-size: 14px;
}

.inputContainer {
  margin-top: 10px;
}

.inputLabel {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #4f5357;
}

.input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.2s;
  color: #23282c;

  &:focus {
    border-color: var(--lw-blue-color);
    outline: none;
  }
}

.invalidInput {
  border-color: #e74c3c;
}

.inputHelp {
  display: block;
  margin-top: 5px;
  font-size: 12px;
  color: #999;
}

.requiredAsterisk {
  color: red;
  margin-left: 0.25em;
}

.video {
  border-radius: 3px;
}
